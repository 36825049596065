<template>
  <!-- HORIZONTAL LAYOUT -->
  <vx-card title="Create User">
    <div class="vx-row mb-6" style="width: 50%">
      <vs-button
        class="ml-4 mt-2"
        color="danger"
        icon-pack="feather"
        icon="icon-arrow-left"
        @click="handleBack()"
        >Back</vs-button
      >
    </div>
    <div class="vx-row">
      <div class="vx-col w-full mb-base">
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/4 w-full">
            <span>*WorkGroup</span>
          </div>
          <div class="vx-col sm:w-3/4 w-full">
            <multiselect
              class="selectExample"
              v-model="selectedWorkGroup"
              :options="optionWorkGroup"
              :multiple="false"
              :filterable="true"
              :allow-empty="true"
              :group-select="true"
              :max-height="100"
              :limit="3"
              :internal-search="true"
              placeholder="Type to search"
              :searchable="true"
              track-by="id"
              label="name"
              @select="SelectWorkGroup()"
              v-validate="'required'"
            />
            <span
              class="text-danger text-sm"
              v-show="errors.has('WorkGroup')"
              >{{ errors.first("WorkGroup") }}</span
            >
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/4 w-full">
            <span>*Role</span>
          </div>
          <div class="vx-col sm:w-3/4 w-full">
            <multiselect
              class="selectExample"
              v-model="selectedRoles"
              :options="optionRoles"
              :multiple="false"
              :filterable="true"
              :allow-empty="true"
              :group-select="true"
              :max-height="100"
              :limit="3"
              :internal-search="true"
              placeholder="Type to search"
              :searchable="true"
              track-by="display_name"
              label="display_name"
              v-validate="'required'"
            />
            <span class="text-danger text-sm" v-show="errors.has('Role')">{{
              errors.first("Role")
            }}</span>
          </div>
        </div>

        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/4 w-full">
            <span>*Status</span>
          </div>
          <div class="vx-col sm:w-3/4 w-full">
            <vs-switch
              v-model="status"
              color="success"
              :disabled="false"
              :value="true"
              :true-value="true"
              :false-value="false"
            >
            </vs-switch>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/4 w-full">
            <span>*Work Id Number</span>
          </div>
          <div class="vx-col sm:w-3/4 w-full">
            <vs-input
              v-model="workIDNumber"
              class="w-full"
              name="workIDNumber"
              disabled
            />
            <span
              class="text-danger text-sm"
              v-show="errors.has('workIDNumber')"
              >{{ errors.first("workIDNumber") }}</span
            >
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/4 w-full">
            <span>*Id Number</span>
          </div>
          <div class="vx-col sm:w-3/4 w-full">
            <vs-input
              v-model="idNumber"
              class="w-full"
              name="idNumber"
              type="number"
              v-validate="'required'"
            />
            <span class="text-danger text-sm" v-show="errors.has('idNumber')">{{
              errors.first("idNumber")
            }}</span>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/4 w-full">
            <span>*Name</span>
          </div>
          <div class="vx-col sm:w-3/4 w-full">
            <vs-input
              v-model="name"
              class="w-full"
              name="name"
              v-validate="'required'"
            />
            <span class="text-danger text-sm" v-show="errors.has('name')">{{
              errors.first("name")
            }}</span>
          </div>
        </div>

        <div class="vx-row mb-6" v-if="isDriver">
          <div class="vx-col sm:w-1/4 w-full">
            <span>Lincense Type</span>
          </div>
          <div class="vx-col sm:w-3/4 w-full">
            <multiselect
              class="selectExample"
              v-model="selectedLincenseType"
              :options="optionLincenseType"
              :multiple="false"
              :filterable="true"
              :allow-empty="true"
              :group-select="true"
              :max-height="100"
              :limit="3"
              :internal-search="true"
              placeholder="Type to search"
              :searchable="true"
              track-by="id"
              label="name"
            />
          </div>
        </div>
        <div class="vx-row mb-6" v-if="isDriver">
          <div class="vx-col sm:w-1/4 w-full">
            <span>License Number</span>
          </div>
          <div class="vx-col sm:w-3/4 w-full">
            <vs-input v-model="licenseNumber" class="w-full" type="number" />
          </div>
        </div>
        <div class="vx-row mb-6" v-if="isDriver">
          <div class="vx-col sm:w-1/4 w-full">
            <span>License Validity</span>
          </div>
          <div class="vx-col w-1/4">
            <vs-input v-model="licenseValidity" class="w-full" type="date" />
          </div>
        </div>
        <div class="vx-row mb-6" v-if="isDriver">
          <div class="vx-col sm:w-1/4 w-full">
            <span>Type</span>
          </div>
          <div class="vx-col sm:w-3/4 w-full">
            <multiselect
              class="selectExample"
              v-model="selectedType"
              :options="optionType"
              :multiple="false"
              :filterable="true"
              :allow-empty="true"
              :group-select="true"
              :max-height="100"
              :limit="3"
              :internal-search="true"
              placeholder="Type to search"
              :searchable="true"
              track-by="id"
              label="name"
              name="source"
              v-validate="'required'"
            />
            <span class="text-danger text-sm" v-show="errors.has('source')"
              >Type is required</span
            >
          </div>
        </div>
        <div class="vx-row mb-6" v-if="!isDriver">
          <div class="vx-col sm:w-1/4 w-full">
            <span>Warehouse</span>
          </div>
          <div class="vx-col sm:w-3/4 w-full">
            <multiselect
              class="selectExample"
              v-model="selectedWarehouse"
              :options="optionWarehouse"
              :multiple="true"
              :filterable="true"
              :allow-empty="true"
              :group-select="true"
              :max-height="100"
              :limit="3"
              :internal-search="true"
              placeholder="Type to search"
              :searchable="true"
              track-by="id"
              label="name"
              name="warehouse"
              @search-change="searchWarehouse($event)"
              v-validate="'required'"
            />
            <span class="text-danger text-sm" v-show="errors.has('warehouse')"
              >Warehouse is required</span
            >
          </div>
        </div>
        <div class="vx-row mb-6" v-if="isDriver">
          <div class="vx-col sm:w-1/4 w-full">
            <span>Warehouse</span>
          </div>
          <div class="vx-col sm:w-3/4 w-full">
            <multiselect
              class="selectExample"
              v-model="selectedWarehouseSingle"
              :options="optionWarehouse"
              :multiple="false"
              :filterable="true"
              :allow-empty="true"
              :group-select="true"
              :max-height="100"
              :limit="3"
              :internal-search="true"
              placeholder="Type to search"
              :searchable="true"
              track-by="id"
              label="name"
              name="warehouse"
              @search-change="searchWarehouse($event)"
              v-validate="'required'"
            />
            <span class="text-danger text-sm" v-show="errors.has('warehouse')"
              >Warehouse is required</span
            >
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/4 w-full">
            <span>*Address</span>
          </div>
          <div class="vx-col sm:w-3/4 w-full">
            <vs-input
              v-model="address"
              class="w-full"
              name="address"
              v-validate="'required'"
            />
            <span class="text-danger text-sm" v-show="errors.has('address')">{{
              errors.first("address")
            }}</span>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/4 w-full">
            <span>*Country</span>
          </div>
          <div class="vx-col sm:w-3/4 w-full">
            <multiselect
              class="selectExample"
              v-model="selectedCountry"
              :options="optionCountry"
              :multiple="false"
              :filterable="true"
              :allow-empty="true"
              :group-select="true"
              :max-height="100"
              :limit="3"
              :internal-search="true"
              placeholder="Type to search"
              :searchable="true"
              track-by="id"
              label="name"
            />
            <span class="text-danger text-sm" v-show="errors.has('Country')">{{
              errors.first("Country")
            }}</span>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/4 w-full">
            <span>*Province</span>
          </div>
          <div class="vx-col sm:w-3/4 w-full">
            <multiselect
              class="selectExample"
              v-model="selectedProvince"
              :options="optionProvince"
              :multiple="false"
              :filterable="true"
              :allow-empty="true"
              :group-select="true"
              :max-height="100"
              :limit="3"
              :internal-search="true"
              placeholder="Type to search"
              :searchable="true"
              track-by="id"
              label="name"
            />
            <span class="text-danger text-sm" v-show="errors.has('Province')">{{
              errors.first("Province")
            }}</span>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/4 w-full">
            <span>*City</span>
          </div>
          <div class="vx-col sm:w-3/4 w-full">
            <multiselect
              class="selectExample"
              v-model="selectedCity"
              :options="optionCity"
              :multiple="false"
              :filterable="true"
              :allow-empty="true"
              :group-select="true"
              :max-height="100"
              :limit="3"
              :internal-search="true"
              placeholder="Type to search"
              :searchable="true"
              track-by="id"
              label="name"
            />
            <span class="text-danger text-sm" v-show="errors.has('City')">{{
              errors.first("City")
            }}</span>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/4 w-full">
            <span>District</span>
          </div>
          <div class="vx-col sm:w-3/4 w-full">
            <multiselect
              class="selectExample"
              v-model="selectedDistrict"
              :options="optionDistrict"
              :multiple="false"
              :filterable="true"
              :allow-empty="true"
              :group-select="true"
              :max-height="100"
              :limit="3"
              :internal-search="true"
              placeholder="Type to search"
              :searchable="true"
              track-by="id"
              label="name"
            />
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/4 w-full">
            <span>Sub District</span>
          </div>
          <div class="vx-col sm:w-3/4 w-full">
            <multiselect
              class="selectExample"
              v-model="selectedSubDistrict"
              :options="optionSubDistrict"
              :multiple="false"
              :filterable="true"
              :allow-empty="true"
              :group-select="true"
              :max-height="100"
              :limit="3"
              :internal-search="true"
              placeholder="Type to search"
              :searchable="true"
              track-by="id"
              label="name"
            />
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/4 w-full">
            <span>Postal Code</span>
          </div>
          <div class="vx-col sm:w-3/4 w-full">
            <vs-input v-model="postal_code" class="w-full" disabled />
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/4 w-full">
            <span>Mobile</span>
          </div>
          <div class="vx-col sm:w-3/4 w-full">
            <vs-input v-model="mobile" class="w-full" type="number" />
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/4 w-full">
            <span>*Email</span>
          </div>
          <div class="vx-col sm:w-3/4 w-full">
            <vs-input
              v-model="email"
              class="w-full"
              type="email"
              name="email"
              v-validate="'required'"
            />
            <span class="text-danger text-sm" v-show="errors.has('email')">{{
              errors.first("email")
            }}</span>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/4 w-full">
            <span>*Password</span>
          </div>
          <div class="vx-col sm:w-3/4 w-full">
            <vs-input
              v-model="password"
              class="w-full"
              type="password"
              name="password"
              v-validate="'required'"
            />
            <span class="text-danger text-sm" v-show="errors.has('password')">{{
              errors.first("password")
            }}</span>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/4 w-full">
            <label
              >Avatar<small style="color: red"
                >(only: jpg, jpeg, png)</small
              ></label
            >
          </div>
          <div class="vx-col sm:w-3/4 w-full">
            <div class="vx-col">
              <input
                id="fileAvatar"
                name="avatar"
                class="w-full inputx"
                type="file"
                ref="avatar"
                accept=".jpg, .jpeg, .png"
              />
            </div>
          </div>
        </div>
        <div v-if="isDriver == true">
          <div class="vx-row mb-6">
            <div class="vx-col sm:w-1/4 w-full">
              <label
                >Attachment
                <small style="color: red">(only: jpg, jpeg, png)</small></label
              >
            </div>
            <div class="vx-col sm:w-3/4 w-full">
              <div class="vx-col">
                <!-- only accept "jpg", "jpeg", "png" -->
                <input
                  id="fileInput"
                  name="attachment"
                  class="w-full inputx"
                  type="file"
                  ref="file"
                  multiple="multiple"
                  accept=".jpg, .jpeg, .png"
                />
              </div>
            </div>
          </div>
          <div
            class="vx-row mb-3 mt-6 w-4/4"
            style="width: 100%; margin-left: 0%"
          >
            <div class="vx-col sm:w-1/4 w-full">
              <span></span>
            </div>
            <div class="vx-col sm:w-3/4 w-full">
              <div class="vx-col sm:w-4/5 w-full">
                <vs-button class="mr-3 mb-2" @click="handleAttachment"
                  >Add Attachment</vs-button
                >
              </div>
            </div>
          </div>
          <!-- display: block -->
          <vs-divider style="width: 60%; margin-left: 30%">
            List Attachment
          </vs-divider>
          <div class="vx-row mb-3 mt-6" style="width: 60%; margin-left: 30%">
            <table class="vs-table vs-table--tbody-table">
              <template>
                <tr
                  class="tr-values vs-table--tr tr-table-state-null selected"
                  v-bind:key="tr.NameFile"
                  v-for="(tr, i) in fileAttachment"
                >
                  <td class="td vs-table--td">{{ tr.NameFile }}</td>
                  <td class="td vs-table--td">
                    <vx-tooltip text="Show" v-if="tr.PathFile != ''">
                      <vs-button
                        type="line"
                        icon-pack="feather"
                        icon="icon-eye"
                        @click.stop="handleShowAttachment(tr)"
                      />
                    </vx-tooltip>
                  </td>

                  <td class="td vs-table--td">
                    <template>
                      <vx-tooltip text="Delete">
                        <vs-button
                          type="line"
                          icon-pack="feather"
                          icon="icon-trash"
                          @click.stop="handleDeleteAttachment(i)"
                        />
                      </vx-tooltip>
                    </template>
                  </td>
                </tr>
              </template>
            </table>
          </div>
        </div>

        <div class="vx-row">
          <div class="vx-col sm:w-6/12 w-full">
            <vs-button class="mr-3 mb-2" @click="SubmitForm()"
              >Submit</vs-button
            >
          </div>
        </div>
      </div>
    </div>
  </vx-card>
</template>

<script>
import { is } from "core-js/core/object";
import moment from "moment";
export default {
  props: {},
  components: {},
  mounted() {
    this.getWorkGroup();
    // this.getRoles();
    this.getCountry();
    this.getWarehouse();
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
  watch: {
    selectedCountry(val) {
      if (val) {
        this.getProvince();
      }
    },
    selectedProvince(val) {
      if (val) {
        this.getCity();
      }
    },
    selectedCity(val) {
      if (val) {
        this.getDistrict();
      }
    },
    selectedDistrict(val) {
      if (val) {
        this.getSubDistrict();
      }
    },
    selectedSubDistrict(val) {
      if (val) {
        this.postal_code = val.postal_code;
      }
    },
    selectedRoles(val) {
      if (val) {
        this.selectedWarehouse = [];
        this.selectedWarehouseSingle = null;
        if (val.name == "driver") {
          this.isDriver = true;
          console.log("masuk sini");
        } else {
          this.licenseNumber = "";
          this.licenseValidity = "";
          this.selectedLincenseType = null;
          this.selectedType = null;
          this.attachment = "";
          this.isDriver = false;
        }
      }
    },
    //set name to upper case
    name(val) {
      this.name = val.toUpperCase();
    },
    selectedWorkGroup(val) {
      if (!val) {
        this.selectedRoles = null;
      }
    },
  },
  data: () => ({
    start: 1,
    end: 0,
    length: 10,
    page: 1,
    search: "",
    order: "id",
    sort: "desc",
    total: 0,
    totalPage: 0,
    totalSearch: 0,
    code: "",
    notes: "",
    avatar: "",
    attachment: "",
    fileAttachment: [],
    IDAttachment: [],
    tommorow: moment().add(1, "days").format("YYYY-MM-DD"),
    IDs: null,
    isDriver: false,
    optionWarehouse: [],
    optionCountry: [],
    optionWorkGroup: [],
    optionRoles: [],
    optionProvince: [],
    optionCity: [],
    optionDistrict: [],
    optionSubDistrict: [],
    optionType: [
      {
        id: 1,
        name: "Internal",
      },
      {
        id: 2,
        name: "External",
      },
      {
        id: 3,
        name: "OneTime",
      },
    ],
    optionLincenseType: [
      {
        id: 1,
        name: "A",
      },
      {
        id: 2,
        name: "B1",
      },
      {
        id: 3,
        name: "B2",
      },
      {
        id: 4,
        name: "C",
      },
      {
        id: 5,
        name: "B1 Umum",
      },
      {
        id: 6,
        name: "B2 Umum",
      },

      {
        id: 7,
        name: "Internasional",
      },
    ],

    selectedWorkGroup: null,
    selectedRoles: null,
    selectedCountry: null,
    selectedProvince: null,
    selectedCity: null,
    selectedDistrict: null,
    selectedSubDistrict: null,
    selectedLincenseType: null,
    selectedType: null,
    selectedWarehouse: [],
    selectedWarehouseSingle: null,
    workIDNumber: "",
    idNumber: null,
    name: "",
    address: "",
    postal_code: "",
    status: true,
    licenseNumber: null,
    licenseValidity: "",
    mobile: null,
    email: "",
    password: "",
  }),
  methods: {
    handleBack() {
      this.$router.push({
        name: "setting.user",
      });
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    containsKey(obj, key) {
      return Object.keys(obj).includes(key);
    },
    SubmitForm() {
      this.$validator.validateAll().then((result) => {
        if (!this.selectedWorkGroup) {
          this.errors.add({
            field: "WorkGroup",
            msg: "The Workgroup field is required",
          });
          result = false;
        }
        if (!this.selectedRoles) {
          this.errors.add({
            field: "Role",
            msg: "The Role field is required",
          });
          result = false;
        }
        if (!this.selectedCountry) {
          this.errors.add({
            field: "Role",
            msg: "The Role field is required",
          });
          result = false;
        }
        if (!this.selectedProvince) {
          this.errors.add({
            field: "Role",
            msg: "The Role field is required",
          });
          result = false;
        }
        if (!this.selectedCity) {
          this.errors.add({
            field: "Role",
            msg: "The Role field is required",
          });
          result = false;
        }

        if (result) {
          this.$vs.loading();

          if (this.status == true) {
            this.status = "1";
          } else {
            this.status = "0";
          }
          this.postData();
        }
      });
    },
    postData() {
      //convert idNumber from number to string
      // this.idNumber = this.idNumber.toString();
      // this.licenseNumber = this.licenseNumber.toString();
      // this.mobile = this.mobile.toString();
      if (this.isDriver) {
        this.selectedWarehouse.push(this.selectedWarehouseSingle);
      }
      let WHIDs = [];
      this.selectedWarehouse.map((el) => {
        WHIDs.push(el.id);
      });

      this.$http
        .post("api/wms/v1/setting/users/create", {
          work_group_id: this.selectedWorkGroup.id,
          role_id: this.selectedRoles.id,
          role_name: this.selectedRoles.name,
          work_id_number: this.workIDNumber,
          id_number: this.idNumber,
          name: this.name,
          address: this.address,
          country: this.selectedCountry ? this.selectedCountry.name : null,
          province: this.selectedProvince ? this.selectedProvince.name : null,
          city: this.selectedCity ? this.selectedCity.name : null,
          district: this.selectedDistrict ? this.selectedDistrict.name : null,
          sub_district: this.selectedSubDistrict
            ? this.selectedSubDistrict.name
            : null,
          postal_code: this.postal_code,
          mobile: this.mobile,
          email: this.email,
          password: this.password,
          is_active: this.status,
          license_type: this.selectedLincenseType
            ? this.selectedLincenseType.name
            : null,
          license_number: this.licenseNumber,
          license_validity: this.licenseValidity,
          type: this.selectedType ? this.selectedType.name : null,
          warehouse_id: WHIDs,
        })
        .then((resp) => {
          // console.log(resp);
          if (resp.code == 200) {
            this.$vs.notify({
              title: "Success",
              text: "User has been added",
              color: "success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
            this.IDs = resp.data;
            this.handleSubmitAttachment();
            this.handleSubmitAvatar();
            this.handleBack();
          } else {
            this.attachment = [];
            this.$vs.notify({
              title: "Error",
              text: resp.message,
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }

          this.$vs.loading.close();
        });
    },
    getWorkGroup() {
      this.$http.get("api/wms/v1/master/work-groups").then((resp) => {
        if (resp.code == 200) {
          this.optionWorkGroup = resp.data.records.map((el) => {
            return {
              id: el.id,
              name: el.name,
              role_group: el.role_group,
            };
          });
        }
      });
    },
    SelectWorkGroup() {
      this.selectedRoles = null;
      this.getRoles();
    },
    getRoles() {
      this.$http
        .get("api/wms/v1/master/roles", {
          params: {
            length: 999,
            page: this.page,
            search: this.search,
            order: this.order,
            sort: this.sort,
            group: this.selectedWorkGroup.role_group,
            source: "WMS",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.optionRoles = resp.data.records.map((el) => {
              return {
                id: el.id,
                name: el.name,
                display_name: el.display_name,
              };
            });
          }
        });
    },
    getCountry() {
      this.$http.get("api/wms/v1/master/country").then((resp) => {
        if (resp.code == 200) {
          this.optionCountry = resp.data.records.map((el) => {
            return {
              id: el.id,
              name: el.name,
            };
          });
        }
      });
    },
    getProvince() {
      this.$http
        .get("api/wms/v1/master/province", {
          params: {
            country_id: this.selectedCountry.id,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.optionProvince = resp.data.records.map((el) => {
              return {
                id: el.id,
                name: el.name,
              };
            });
          }
        });
    },
    getCity() {
      this.$http
        .get("api/wms/v1/master/city", {
          params: {
            province_id: this.selectedProvince.id,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.optionCity = resp.data.records.map((el) => {
              return {
                id: el.id,
                name: el.name,
              };
            });
          }
        });
    },
    getDistrict() {
      this.$http
        .get("api/wms/v1/master/district", {
          params: {
            city_id: this.selectedCity.id,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.optionDistrict = resp.data.records.map((el) => {
              return {
                id: el.id,
                name: el.name,
              };
            });
          }
        });
    },
    getSubDistrict() {
      this.$http
        .get("api/wms/v1/master/sub-district", {
          params: {
            city_id: this.selectedCity.id,
            district_id: this.selectedDistrict.id,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.optionSubDistrict = resp.data.records.map((el) => {
              return {
                id: el.id,
                name: el.name,
                postal_code: el.postal_code,
              };
            });
          }
        });
    },
    searchWarehouse(val) {
      if (val) {
        if (val.length < 3) {
          return;
        }
      }
      this.getWarehouse(val);
    },
    getWarehouse(val) {
      this.$http
        .get("api/wms/v1/master/warehouses", {
          params: {
            length: this.length,
            page: this.page,
            search: val,
            order: this.order,
            sort: this.sort,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.optionWarehouse = resp.data.records.map((el) => {
              return {
                id: el.id,
                name: el.name,
              };
            });
          }
        });
    },
    mappingAttachment() {
      this.adjustment.Attachment.map((v) => {
        this.fileAttachment.push({
          ListID: v.ListID,
          NameFile: v.NameFile,
          PathFile: v.PathFile,
        });
      });
    },
    handleAttachment() {
      console.log("iniattch", this.$refs.file);
      if (this.$refs.file.files.length > 0) {
        this.file = this.$refs.file.files;
        // this.fileAttachment = [];
        for (let i = 0; i < this.$refs.file.files.length; i++) {
          console.log(this.file[i]);
          // this.uploadData(i);
          this.fileAttachment.push({
            NameFile: this.file[i].name,
            File: this.file[i],
          });
        }
        document.querySelector("#fileInput").value = "";
      } else {
        this.$vs.notify({
          title: "Error",
          text: "Please select file to import.",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
      }
    },
    handleShowAttachment(file) {
      let objectURL;
      // console.log(file)
      objectURL = URL.createObjectURL(file.File);

      // link.download = file.name; // this name is used when the user downloads the file
      var href = objectURL;
      window.open(href, "_blank").focus();
    },
    handleDeleteAttachment(index) {
      // const deleteAttachment =this.fileAttachment[index]
      this.fileAttachment = this.fileAttachment.filter((r, i) => {
        return i != index;
      });
    },
    handleSubmitAttachment() {
      console.log(this.fileAttachment);

      this.$vs.loading();
      if (this.fileAttachment.length > 0) {
        const formData = new FormData();
        for (let index = 0; index < this.fileAttachment.length; index++) {
          formData.append("attachments[]", this.fileAttachment[index].File);
        }
        formData.append("user_id", this.IDs);

        this.$http
          .post("api/wms/v1/setting/users/attachment", formData)
          .then((resp) => {
            if (resp.code == 200) {
              this.$vs.loading.close();
              this.$vs.notify({
                title: "Success",
                text: "Attachment has been added",
                color: "success",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-check",
              });
            } else {
              this.$vs.loading.close();
              this.$vs.notify({
                title: "Error",
                text: resp.message,
                color: "danger",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-check",
              });
            }
          });
      } else {
        this.$vs.loading.close();
      }
    },
    handleSubmitAvatar() {
      this.$vs.loading();
      if (this.$refs.avatar.files.length > 0) {
        const formData = new FormData();
        formData.append("avatar", this.$refs.avatar.files[0]);
        formData.append("user_id", this.IDs);

        this.$http
          .post("api/wms/v1/setting/users/avatar", formData)
          .then((resp) => {
            if (resp.code == 200) {
              this.$vs.loading.close();
              this.$vs.notify({
                title: "Success",
                text: "Avatar has been added",
                color: "success",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-check",
              });
            } else {
              this.$vs.loading.close();
              this.$vs.notify({
                title: "Error",
                text: resp.message,
                color: "danger",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-check",
              });
            }
          });
      } else {
        this.$vs.loading.close();
      }
    },
  },
};
</script>
